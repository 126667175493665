<template>
    <delivery-detail :branch="branch" :delivery="address" :lat="coordinates.lat" :long="coordinates.long" :quantity="quantity" class="container"/>
</template>


<script>
import DeliveryDetail from '../components/modals/DeliveryDetail.vue'
import { http as  nodeService} from "../../../common/services/node-service";

export default {
    components: { DeliveryDetail },
    name: "DeliveryDetails",
    data() {
        return {
            branch: {},
            coordinates: {},
            quantity: 0,
            address: {
                available: false,
                base_price: 0,
                coords: null,
                km: 0,
                min: 0,
                minimum_quantity: 0,
                price: 0,
                price_per_km: 0,
                price_per_min: 0,
                price_per_quantity: 0
            },
            show: false,
            loading : true,
        }
    },
    async mounted() {
        this.coordinates = {lat: this.$route.query.lat, long: this.$route.query.long}
        try {
            this.loading = true
            const stores = await this.$store.dispatch('branches/getBranches');
            const branch = this.$options.filters.filterBranch(stores, parseInt(this.$route.query.branch))
            this.branch = branch
            let response = await nodeService.post("stores/limit/verify", {
                address:[{
                    latitud: this.coordinates.lat,
                    longitud: this.coordinates.long,
                }],
                branch_codes: [parseInt(this.$route.query.branch)],
                calculate_prices: 1
            });
            if(response.data.data[0].verify.length > 0){
                this.address = response.data.data[0].verify[0].limits
            }
        } catch (error) {
            console.log(error);
        }finally{
            this.loading = false
        }
        let chat = document.querySelector("#mobile-chat-container")
        if(chat)
        chat.style.display="none"
    },
}
</script>

<style scoped>
    .modal-main-container::v-deep > .modal-main {
        width: 100%;
        height: 100%;
        margin: 0;
        max-height: none;
        border-radius: 0;
        padding: 10px;
    }
    .modal-main-container::v-deep > .modal-main > .d-flex.justify-content-end {
        display: none !important;
    }
    .modal-main-container::v-deep > .modal-main > .content {
        max-height: none;
    }
    .modal-main-container::v-deep {
        position: relative;
        background-color: transparent !important;
    }
    .modal-main-container::v-deep > .modal-main > .content > div > .map > .delivery-map > .mgl-map-wrapper {
        height: 400px !important;
    }
</style>
