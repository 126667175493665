<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_16">
            <g>
                <g>
                <g>
                    <path class="st8" d="M210,66.5H40c0,11.7-9.6,21.3-21.3,21.3v74.4c11.7,0,21.3,9.6,21.3,21.3H210c0-11.7,9.6-21.3,21.3-21.3      V87.8C219.6,87.8,210,78.2,210,66.5z M63,135.5c-7.3,1.2-13.5-5-12.3-12.3c0.7-4.4,4.3-8,8.7-8.7c7.3-1.2,13.5,5,12.3,12.3      C71,131.2,67.4,134.8,63,135.5z M127.4,170.9c-27.3,1.4-49.7-21-48.4-48.3c1.2-23.4,20.2-42.3,43.5-43.5      c27.3-1.4,49.7,21,48.4,48.3C169.8,150.8,150.8,169.7,127.4,170.9z M190.6,135.5c-7.3,1.2-13.5-5-12.3-12.3      c0.7-4.4,4.3-8,8.7-8.7c7.3-1.2,13.5,5,12.3,12.3C198.5,131.2,195,134.8,190.6,135.5z"/>
                </g>
                <g>
                    <path class="st8" d="M240.6,200.1H9.4c-4.9,0-8.9-3.8-8.9-8.5V58.4c0-4.7,4-8.5,8.9-8.5h231.2c4.9,0,8.9,3.8,8.9,8.5v133.2      C249.5,196.3,245.5,200.1,240.6,200.1z M9.4,57.3c-0.6,0-1.1,0.5-1.1,1.1v133.2c0,0.6,0.5,1.1,1.1,1.1h231.2      c0.6,0,1.1-0.5,1.1-1.1V58.4c0-0.6-0.5-1.1-1.1-1.1H9.4z"/>
                </g>
                </g>
                <path class="st8" d="M139.6,144.6c-2.4,2.3-5.5,3.9-9.3,4.7v3c0,1.3-0.4,2.5-1.3,3.3c-0.9,0.9-2,1.3-3.3,1.3    c-1.3,0-2.5-0.4-3.3-1.3c-0.8-0.9-1.3-2-1.3-3.3v-2.8c-2.2-0.3-4.3-0.8-6.3-1.5c-2.5-0.9-4.6-2-6.4-3.5c-0.7-0.5-1.2-1.1-1.5-1.8    c-0.3-0.7-0.4-1.4-0.4-2.3c0-1.2,0.4-2.3,1.1-3.2c1-1.3,2.1-1.5,2.9-1.5c0.6,0,1.1,0.1,1.7,0.3c0.4,0.2,1,0.4,1.6,0.8    c1.9,1.3,3.8,2.2,5.6,2.7c1.8,0.6,3.7,0.8,5.7,0.8c2.6,0,4.6-0.5,6-1.5c1.2-0.9,1.8-2.1,1.8-3.8c0-0.9-0.3-1.6-0.9-2.1    c-0.7-0.7-1.7-1.2-2.8-1.6c-1.2-0.4-3-1-5.3-1.6c-3.3-0.8-6-1.7-8.1-2.5c-2.2-0.9-4.1-2.3-5.7-4.2c-1.7-2-2.5-4.6-2.5-7.8    c0-3.8,1.4-7.2,4.1-9.9c2.5-2.4,5.6-4,9.5-4.8v-3c0-1.3,0.4-2.5,1.3-3.3c1.6-1.7,5-1.7,6.6,0c0.8,0.8,1.3,2,1.3,3.3v2.9    c4.1,0.7,7.8,2.3,10.8,4.8c1.3,1,2,2.4,2,4c0,1.2-0.4,2.3-1.1,3.2c-1,1.2-2.1,1.5-2.9,1.5c-0.6,0-1.1-0.1-1.6-0.3    c-0.4-0.1-0.9-0.4-1.7-0.9c-1.7-1.2-3.3-2.1-4.8-2.7c-1.4-0.6-3.1-0.9-5.1-0.9c-2.4,0-4.3,0.5-5.7,1.5c-1.3,0.9-1.9,2.2-1.9,3.9    c0,1.1,0.3,1.9,1,2.6c0.8,0.8,1.8,1.3,3,1.8c1.3,0.5,3.2,1,5.5,1.6c3.2,0.8,5.8,1.6,7.9,2.5c2.2,0.9,4,2.3,5.6,4.1    c1.6,1.9,2.4,4.4,2.4,7.6C143.6,138.7,142.2,142,139.6,144.6z"/>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconMoney',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
</style>
