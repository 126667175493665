<template>
    <main-modal :title="''" @closeModal="$emit('closeModal')" class="delivery-detail">
        <div class="row m-0">
            <div class="col-md-12">
                <h5 class="weight-600 mb-2 mb-md-3">{{$t('checkout.delivery_detail')}}</h5>
            </div>
            <div class="col-md-12">
                <h6 class="subtitle-16">{{$t("checkout.description_delivery_detail")}}.</h6>
                <div class="d-flex align-items-center mb-2 mt-4">
                    <icon-money :size="25" class="me-2"/>
                    <span class="title-16 mb-0">{{$t("checkout.base_price")}}</span>
                    <span class="ms-2">{{delivery.base_price | moneyFormat}}</span>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                        <thead style="border-bottom: 2px solid #dee2e6;">
                            <tr class="align-center text-center weight-600">
                                <th scope="col">{{$t("checkout.rates")}}</th>
                                <th scope="col">{{$t("checkout.your_order")}}</th>
                                <th scope="col">{{$t("checkout.cost")}}</th>
                                <th scope="col">{{$t("checkout.subtotal_delivery")}}</th>
                            </tr>
                        </thead>
                        <tbody class="color-dark-gray-300 text-center">
                            <tr>
                                <td class="title-15 mb-0">{{$t("checkout.minute")}}</td>
                                <td>{{delivery.min}} Min</td>
                                <td>{{delivery.price_per_min | moneyFormat}}</td>
                                <td>{{delivery.price_per_min | moneyFormatMultiply(delivery.min)}}</td>
                            </tr>
                            <!-- <tr v-if="delivery.price_per_quantity != 0">
                                <td class="title-15 mb-0">{{$t("checkout.quantity")}}</td>
                                <td>{{quantity}}</td>
                                <td>{{delivery.price_per_quantity | moneyFormat}}</td>
                                <td>{{delivery.price_per_quantity | moneyFormat}}</td>
                            </tr> -->
                            <tr>
                                <td class="title-15 mb-0">{{$t("checkout.kilometer")}}</td>
                                <td>{{delivery.km}} km</td>
                                <td>{{delivery.price_per_km | moneyFormat}}</td>
                                <td>{{delivery.price_per_km | moneyFormatMultiply(delivery.km)}}</td>
                            </tr>
                                <tr>
                                <td class="title-15 mb-0">{{$t("checkout.base")}}</td>
                                <td>-</td>
                                <td>-</td>
                                <td>{{delivery.base_price | moneyFormat}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-2 mt-md-3 d-flex justify-content-end">
                    <h6 class="mb-0 color-main-red title-16 weight-500">{{$t("checkout.total_cost")}} <b class="ms-2 color-main-navy weight-600">{{delivery.price | moneyFormat}}</b></h6>
                </div>
            </div>
            <div class="col-md-12 mt-2 mt-md-1 map">
                <div class="d-flex align-items-center mb-2">
                    <icon-pin :size="20" class="me-2"/>
                    <span class="title-16 mb-0">{{$t("checkout.order_delivery_route")}}</span>
                </div>
                <div class="delivery-map">
                    <MglMap class="delivery-mgl-map" ref="map" :center="[long, lat]" :accessToken="accessToken" :zoom="10" :dragRotate="false"  @load="onMapLoaded" :mapStyle="mapStyle" >
                        <MglGeolocateControl :trackUserLocation="true" position="top-right" />
                        <MglMarker :coordinates="[long, lat]" color="var(--main-red)"/>
                        <MglMarker :coordinates="[branch.long, branch.lat]">
                            <div slot="marker" class="icon-store">
                                <icon-store :size="25" :color="'var(--main-red)'"/>
                            </div>
                        </MglMarker>
                        <MglNavigationControl position="top-right"/>
                    </MglMap>
                </div>
            </div>
            <div class="d-flex align-items-center mt-2">
                <div class="legend-map"></div>
                <span class="subtitle-14 ms-2">{{$t("account.area_available_for_delivery")}}.</span>
            </div>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import IconMoney from '../../assets/svg/iconMoney.vue'
import { MglMap, MglMarker, MglGeolocateControl, MglNavigationControl } from 'v-mapbox'
import Mapbox from "mapbox-gl";
import IconPin from '../../../../common/svg/iconPin.vue';
import { addressesMixin } from '../../../account/helpers/mixins/addresses-mixin'
import IconStore from '../../assets/svg/iconStore.vue';

export default {
    name: "DeliveryDetail",
    components:{
        MainModal,
        IconMoney,
        MglMap,
        MglMarker,
        MglGeolocateControl,
        MglNavigationControl,
        IconPin,
        IconStore
    },
    props:{
        lat:{
            type: [Number, String]
        },
        long:{
            type: [Number, String]
        },
        branch:{
            type: Object
        },
        delivery:{
            type: Object
        },
        quantity:{
            type: Number
        }
    },
    mixins: [addressesMixin],
    computed:{
        sources() {
            let sources = [];
            for (let layer of this.layers){
                sources.push({
                'type': 'geojson',
                'data': layer
                })
            }
            return sources;
        },  
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY, // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus', // your map style
            center: [-70.70238447994983, 19.45556310283655],
            layers: []
        }
    },
    methods:{
        async onMapLoaded(event){
            await this.getLimits(this.branch)
            this.mapbox = Mapbox;
            this.sources.forEach((source, id)=>{
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                    'id': 'limit-areas-fill-'+id,
                    'type': 'fill',
                    'source': 'limit-areas-'+id,
                    'layout': {},
                    'paint': {
                        'fill-color': '#5A8CDB',
                        'fill-opacity': 0.3
                    }
                    },
                );

            })
            event.map.on('style.load', async () => {
                this.sources.forEach((source, id)=>{
                event.map.addSource('limit-areas-'+id, source);
                event.map.addLayer(
                    {
                        'id': 'limit-areas-fill-'+id,
                        'type': 'fill',
                        'source': 'limit-areas-'+id,
                        'layout': {},
                        'paint': {
                        'fill-color': '#c40316',
                        'fill-opacity': 0.5
                        }
                    },
                );

                })
            });
            const animationOptions = {
                animate: true, duration: 4000, essential: true
            }
            setTimeout(() => {
                    event.map.zoomTo(14, animationOptions)
            }, 2000);
            this.addRoute(this.delivery.coords)
        },
        addRoute(coords){
            if (this.$refs.map.map.getSource('route')) {
                this.$refs.map.map.removeLayer('route');
                this.$refs.map.map.removeSource('route')
            } else{
                this.$refs.map.map.addLayer({
                "id": "route",
                "type": "line",
                "source": {
                    "type": "geojson",
                    "data": {
                    "type": "Feature",
                    "properties": {},
                    "geometry": coords
                    }
                },
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": "#3b9ddd",
                    "line-width": 8,
                    "line-opacity": 0.8
                }
                });
                }
        }
    }
}
</script>

<style scoped>
    .delivery-map{
        border-radius: 10px;
        overflow: hidden;
    }
    .table-bordered {
        border-top-right-radius: 15px !important;
        border-top-left-radius: 15px !important;
    }
    td{
        width: 25%;
    }
    .legend-map{
        height: 20px;
        width: 20px;
        background: #a4bbde;
        border-radius: 3px;
    }
    .delivery-mgl-map{
        height: 300px;
    }
    .icon-store{
        background-color: #fff;
        border-radius: 50%;
        padding: 5px;
    }
    @media (max-width: 576px) {
        .table-bordered{
            font-size: 13px;
        }
        .table-bordered > tbody > tr > td{
            font-size: 13px;
        }
        .delivery-mgl-map{
            height: 200px;
        }
        .mgl-map-wrapper ::v-deep > .mapboxgl-map > .mapboxgl-canvas-container > .mapboxgl-canvas{
            border-radius: 8px;
        }
    }
</style>
